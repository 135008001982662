<template>
  <div class="common-bg">
    <van-nav-bar
      title="激活记录"
      left-arrow
      @click-left="onClickLeft"
    />
    <div v-if="list.length > 0">
      <div v-for="item in list" :key="item.id">
        <div class="stock-card">
          <div class="card-li">
            <span class="text-grey">激活金额：</span>
            <span class="font-weight text-warning">￥{{Number(item.money).toFixed(2)}}</span>
          </div>
          <div class="card-li">
            <span class="text-grey">激活时间：</span>
            <span class="font-weight">{{item.createTime}}</span>
          </div>
          <div class="card-li">
            <span class="text-grey">状态：</span>
            <van-tag type="warning" v-if="item.status === 1">等待审核</van-tag>
            <van-tag type="success" v-if="item.status === 2">激活成功</van-tag>
            <van-tag type="danger" v-if="item.status === 3">拒绝</van-tag>
          </div>
          <div class="card-li">
            <span class="text-grey">备注：</span>
            <span class="font-weight">{{item.note}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center padded-t-15" v-else>
      暂无记录
    </div>
  </div>
</template>

<script>
import { getActivationLog } from '@/utils/api.js'
export default {
  name: 'activationLog',
  data () {
    return {
      list: []
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      getActivationLog().then(res => {
        this.list = res.data
      })
    },
    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
  .stock-card {
    padding: 15px;
    background: hsla(0,0%,100%,.6);
    border-radius: 8px;
    font-size: 14px;
    margin: 10px;
    .card-li {
      padding: 3px 0;
    }
  }
</style>
